import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["expandableBlock", "button"]

  toggleCollapse() {
    this.expandableBlockTargets.forEach((block) => {
      block.classList.toggle("hidden")
    })

    this.buttonTarget.classList.toggle("expanded")
  }
}